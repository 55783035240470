import { ready } from '#js/components/utils'

export default function employeeNotificationToggle (selector) {
  document.querySelectorAll(selector).forEach(function (element) {
    element.addEventListener('click', (e) => {
      if (!element.checked) {
        window.dataLayer.push({
          event: 'unsubscribe',
          notification: element.name
        })
      }
    })
  })
}

ready(function () {
  employeeNotificationToggle('[data-notification-toggle]')
})
